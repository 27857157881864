import { useState, useCallback } from "react";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import {db} from "../firebase/firebase.utilities";


function useGetProductsInfo() {

  const [productsInfo, setProductsInfo] = useState()
  const [isLoading, setIsLoading] = useState(false)

  
  const getProductInfo = useCallback(async (sku_arr) => {
    //console.log('SKU_ARR length: ', sku_arr,sku_arr.length)
     let productsInfo = [];
    setIsLoading(true)
    try {
      //console.log("sku_arr: ", sku_arr)
      let skuArr_01
      let skuArr_02
      let skuArr_03
      let skuArr_04
      let q_01
      let q_02
      let q_03
      let q_04
      if(sku_arr.length > 12) {
        skuArr_01 = sku_arr.slice(0, Math.floor(sku_arr.length/4))
        skuArr_02 = sku_arr.slice(skuArr_01.length, 2 * skuArr_01.length)
        skuArr_03 = sku_arr.slice(2 * skuArr_01.length, 3 * skuArr_01.length)
        skuArr_04 = sku_arr.slice(3 * skuArr_01.length, sku_arr.length)
            q_01 = query(collection(db, "products"), where("sku", 'in',skuArr_01))
            q_02 = query(collection(db, "products"), where("sku", 'in',skuArr_02))
            q_03 = query(collection(db, "products"), where("sku", 'in',skuArr_03))
            q_04 = query(collection(db, "products"), where("sku", 'in',skuArr_04))
      }
      else if(sku_arr.length > 8) {
        skuArr_01 = sku_arr.slice(0, Math.floor(sku_arr.length/3))
        skuArr_02 = sku_arr.slice(skuArr_01.length, 2 * skuArr_01.length)
        skuArr_03 = sku_arr.slice(2 * skuArr_01.length, sku_arr.length)
            q_01 = query(collection(db, "products"), where("sku", 'in',skuArr_01))
            q_02 = query(collection(db, "products"), where("sku", 'in',skuArr_02))
            q_03 = query(collection(db, "products"), where("sku", 'in',skuArr_03))
      }
      else if(sku_arr.length > 4) {
        skuArr_01 = sku_arr.slice(0, Math.floor(sku_arr.length/2))
        skuArr_02 = sku_arr.slice(Math.floor(sku_arr.length/2), sku_arr.length)
            q_01 = query(collection(db, "products"), where("sku", 'in',skuArr_01))
            q_02 = query(collection(db, "products"), where("sku", 'in',skuArr_02))
      }else if (sku_arr.length <= 4){
        q_01 = query(collection(db, "products"), where("sku", 'in',sku_arr))
      }
//console.log(' q_01:', q_01)

      const querySnapshot_01 = await getDocs(q_01);
      querySnapshot_01.forEach((doc) => {
         const productInfoList = doc.data();
         //console.log('productInfoList:',productInfoList)
         productsInfo.push(productInfoList);
     });
     let querySnapshot_02

if(q_02 && q_02 !== undefined) {
  querySnapshot_02 = await getDocs(q_02);
  querySnapshot_02.forEach((doc) => {
    const productInfoList = doc.data();
    //console.log('productInfoList',productInfoList)
    productsInfo.push(productInfoList);
 });
}

let querySnapshot_03
if(q_03 && q_03 !== undefined) {
  querySnapshot_03 = await getDocs(q_03);
  querySnapshot_03.forEach((doc) => {
    const productInfoList = doc.data();
    //console.log('productInfoList',productInfoList)
    productsInfo.push(productInfoList);
 });
}

let querySnapshot_04
if(q_04 && q_04 !== undefined) {
  querySnapshot_04 = await getDocs(q_04);
  querySnapshot_04.forEach((doc) => {
    const productInfoList = doc.data();
    //console.log('productInfoList',productInfoList)
    productsInfo.push(productInfoList);
 });
}
    //console.log('productsInfo: ',productsInfo)
    setProductsInfo(productsInfo)
    setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err);
    }
  },[]);

    return {
        productsInfo : productsInfo,
        isLoadingProductInfo : isLoading,
        getProductInfoFunc : getProductInfo,
    }
}

export default useGetProductsInfo
