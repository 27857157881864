import React, { useState, useRef, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./carouselStyle.css";
import HotbuyTile from '../Components/HotbuyTile';
import CategoryTile from '../Components/CategoryTile';
import CutpriceTile from '../Components/CutpriceTile';
import { taggingDotsHelper, taggingArrowsHelper } from '../helpers/taggingHelper';
import SwipeIcon from '../UI/SwipeIcon';

const WhiteTilesSlider = ({
  productsInfo,
  bgImage,
  headerColor,
  categoryBtnText,
  categoryBtnBgColour,
  categoryBtnBgHoverColour,
  showArrows,
  largeTile,
  autoplay,
  autoPlaySpeed,
  sliderID,
  swipeable,
  pageUrl
}) => {
  const carouselRef = useRef(null);
  const [isVisibleSwipe, setIsVisibleSwipe] = useState(false);
  const [isActive1, setIsActive1] = useState();
  const [isActive2, setIsActive2] = useState();
  const [isActive3, setIsActive3] = useState();

  const responsiveWhiteTile = {
    desktop: {
      breakpoint: { max: 10000, min: 1200 },
      items: 4,
      slidesToSlide: 1,
    },
    tabletLandscape: {
      breakpoint: { max: 1199, min: 992 },
      items: 3.37,
      slidesToSlide: 1,
    },
    tabletPortrait: {
      breakpoint: { max: 991, min: 768 },
      items: 2.45,
      slidesToSlide: 1,
    },
    mobile0: {
      breakpoint: { max: 767, min: 580 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile1: {
      breakpoint: { max: 579, min: 400 },
      items: 1.35,
      slidesToSlide: 1,
    },
    mobile2: {
      breakpoint: { max: 399, min: 1 },
      items: 1.25,
      slidesToSlide: 1,
    },
    mobile3: {
      breakpoint: { max: 352, min: 1 },
      items: 1.15,
      slidesToSlide: 1,
    },
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const { index, active } = rest;
   
    active && sliderID === '1' && setIsActive1(index)
    active && sliderID === '2' && setIsActive2(index)
    active && sliderID === '3' && setIsActive3(index)

    return (
      <button
        className={`react-multi-carousel-dot ${
          active ? 'react-multi-carousel-dot--active' : ''
        }`}
        onClick={() => {
          taggingDotsHelper(pageUrl, index, sliderID);
          onClick();
        }}
      ></button>
    );
  };

  const CustomRightArrow = ({ onClick}) => (
    
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={() => {
        carouselRef.current.next();
        const taggingIndex = sliderID === '1' ? isActive1 + 2 : (sliderID === '2' ? isActive2 + 2 : isActive3 + 2);
        taggingArrowsHelper(pageUrl, 'Right', taggingIndex, sliderID);
        onClick();
      }}
    />
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={() => {
        carouselRef.current.previous();
        let taggingIndex;
        if (sliderID === '1') {
          taggingIndex = isActive1 === 0 ? 8 : isActive1;
        } else if (sliderID === '2') {
          taggingIndex = isActive2 === 0 ? 8 : isActive2;
        } else if (sliderID === '3') {
          taggingIndex = isActive3 === 0 ? 8 : isActive3;
        }
        taggingArrowsHelper(pageUrl, 'Left', taggingIndex, sliderID);
        onClick();
      }}
    />
  );
  

  let whiteTiles;

  if (productsInfo && productsInfo !== undefined) {
    whiteTiles = productsInfo.map((pInfo, i) => {
      if (pInfo.IsInStock !== "N"){
        const price = pInfo.price ? JSON.parse(pInfo.price) : null;
        const ticketSlashedPrice = pInfo.promoPrice && pInfo.promoPrice.ticketSlashedPrice ? JSON.parse(pInfo.promoPrice.ticketSlashedPrice) : null;

        if (pInfo.type === 'Category') {
          return (
            <CategoryTile
              info={pInfo}
              key={pInfo.sku}
              sku={pInfo.sku}
              bgImage={bgImage}
              categoryBtnText={categoryBtnText}
              categoryBtnBgColour={categoryBtnBgColour}
              categoryBtnBgHoverColour={categoryBtnBgHoverColour}
              headerColor={headerColor}
              largeTile={largeTile}
              index={i}
              sliderID={sliderID}
              pageUrl={pageUrl}
            />
          );
        } else if (price && ticketSlashedPrice && ticketSlashedPrice > price) {
          return (
            <CutpriceTile
              info={pInfo}
              key={pInfo.sku}
              sku={pInfo.sku}
              bgImage={bgImage}
              headerColor={headerColor}
              largeTile={largeTile}
              index={i}
              sliderID={sliderID}
              pageUrl={pageUrl}
            />
          );
        } else {
          return (
            <HotbuyTile
              info={pInfo}
              key={pInfo.sku}
              sku={pInfo.sku}
              bgImage={bgImage}
              headerColor={headerColor}
              largeTile={largeTile}
              index={i}
              sliderID={sliderID}
              pageUrl={pageUrl}
            />
          );
        }
      }
    });
  }

  return (
    <div className={`whiteTiles_slider marg-bottom-20 ${!showArrows ? 'noArrows' : ''}`}>
      {swipeable ? <SwipeIcon position={sliderID} isVisibleSwipe={isVisibleSwipe}/>: null}
      {productsInfo && productsInfo !== undefined ? (
        <Carousel
          ref={carouselRef}
          arrows={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsiveWhiteTile}
          ssr={true}
          infinite={true}
          partialVisible={true}
          autoPlay={autoplay}
          autoPlaySpeed={autoPlaySpeed}
          keyBoardControl={true}
          customTransition="transform 400ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
          customDot={<CustomDot />}
          focusOnSelect={false}
          preventDefaultTouchmoveEvent={true}
        >
          {whiteTiles}
        </Carousel>
      ) : null}
    </div>
  );
};

export default WhiteTilesSlider;
