import React, { useState, useEffect } from "react";
import "./SwipeIcon.css";

function SwipeIcon(props) {
    // console.log('props.position',props.position)
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!props.isVisibleSwipe) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // Set the timeout to match the duration of the transition

      return () => clearTimeout(timeout);
    }
  }, [props.isVisibleSwipe]);

  return (
    <>
      <div className={`whiteTiles-swipe whiteTiles-swipe-${props.position} ${isVisible ? "" : "swipe-fade-out"} ${props.isVisibleSwipe ? "" : "hidden"}`}>
        <div
          className="path"
          style={{ animation: "swipe-dot 2s 0.5s infinite"}}
        ></div>
        <div
          className="hand-icon"
          style={{ animation: "swipe-hand 2s infinite" }}
        ></div>
      </div>
    </>
  );
}

export default SwipeIcon;