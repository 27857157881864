
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyAQHPZ_HvzzEMPH7Lb8aSG9OpTkzlpU4Sg",
    authDomain: "tgg-web-api.firebaseapp.com",
    databaseURL: "https://tgg-web-api.firebaseio.com",
    projectId: "tgg-web-api",
    storageBucket: "tgg-web-api.appspot.com",
    messagingSenderId: "1060176745014",
    appId: "1:1060176745014:web:56f19364509afb5a0c3e97"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

//console.log('db:',db)



export {db }
